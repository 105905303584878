import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { memberApiFactory, Search, serviceTypesApiFactory } from '@tkww/the-bash-frontend';

import { gmConfig } from 'helpers/config';
import localStorageHelper from 'helpers/localStorageHelper';
import cookieHelper from 'helpers/cookieHelper';
import isEmpty from 'lodash.isempty';
import { trackEvent } from 'state/modules/analytics';

const SearchForm = (props) => {
  const { enableMemberSearch, onSearchTrackingData, ...rest } = props;
  const dispatch = useDispatch();

  const onSearchTracking = (onSearchData) => {
    if (!isEmpty(onSearchTrackingData)) {
      const { name, properties } = onSearchTrackingData;
      const { serviceType, location } = onSearchData;
      const trackingData = {
        ...properties,
        searchedCategory: serviceType.name,
        searchedLocation: location?.displayName,
      };
      dispatch(trackEvent(name, trackingData));
    }
  };

  // This should be migrated out of here to rely on a prop to enable/disable member search instead of
  // the API client existing and returning nothing
  const memberApiClient = enableMemberSearch
    ? memberApiFactory(`https://${gmConfig.domains.www}`)
    : { getMembers: () => [] };

  return (
    <Search
      {...rest}
      onSearch={onSearchTracking}
      membersApiClient={memberApiClient}
      cookieHelper={cookieHelper}
      localStorageHelper={localStorageHelper}
      serviceTypesApiClient={serviceTypesApiFactory(`https://${gmConfig.domains.www}`)}
    />
  );
};

SearchForm.props = {
  onSearchTrackingData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    properties: PropTypes.shape({}),
  }),
  enableMemberSearch: PropTypes.bool,
};

SearchForm.defaultProps = {
  onSearchTrackingData: null,
  enableMemberSearch: false,
};

export default SearchForm;
