import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Link } from '@tkww/the-bash-frontend';

import Image from 'components/Image';
import ArticleTag from 'components/Article/ArticleTag';
import buildArticleLink from 'helpers/buildArticleLink';
import getSxAttributes from 'helpers/getSxAttributes';
import sxPropType from 'propTypes/sx';

const TITLE_LINE_HEIGHT = 22;

export const ArticleCard = ({
  hed,
  thumbSquareUrl,
  slug,
  topicTagName,
  onClick,
  lazyLoadThumbnail,
  sx,
  sxTitle,
}) => {
  return (
    <Box
      mb={4}
      data-testid="article-card"
      sx={(theme) => ({
        '& .card': {
          display: 'block',
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.text.primary,
            boxShadow: theme.constants.boxShadows.dark,
            textDecoration: 'none',
          },
          ...getSxAttributes(theme, sx),
        },
      })}
    >
      {topicTagName && (
        <Box mb={1.5}>
          <ArticleTag data-testid="topic-tag" name={topicTagName} />
        </Box>
      )}
      <Link
        testid="article-link"
        path={buildArticleLink(slug)}
        onClick={() => onClick({ hed, slug })}
        className="test-article-link card"
      >
        <Image
          height={260}
          sx={{
            width: '100%',
            minHeight: 260,
            objectFit: 'cover',
          }}
          src={thumbSquareUrl}
          alt=""
          lazyLoad={lazyLoadThumbnail}
        />
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey[300]}`,
            borderTop: 'none',
            padding: 1.5,
            backgroundColor: theme.palette.background.default,
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: 'bold',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: `${TITLE_LINE_HEIGHT}px`,
              height: TITLE_LINE_HEIGHT * 2,
              margin: 0,
              ...getSxAttributes(theme, sxTitle),
            })}
          >
            {hed}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export const articleCardProptypes = {
  onClick: PropTypes.func,
  hed: PropTypes.string.isRequired,
  lazyLoadThumbnail: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  thumbSquareUrl: PropTypes.string.isRequired,
  topicTagName: PropTypes.string,
  sx: sxPropType,
  sxTitle: sxPropType,
};

ArticleCard.defaultProps = {
  onClick: () => {},
  lazyLoadThumbnail: true,
  topicTagName: null,
  sx: {},
  sxTitle: {},
};

ArticleCard.propTypes = articleCardProptypes;

export default ArticleCard;
