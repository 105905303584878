import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/Tag';

const ArticleTag = ({ name, onClick, ...other }) => {
  const slugLink = encodeURI(name.toLowerCase());
  const path = `/articles/tag/${slugLink}`;

  return <Tag {...other} name={name} onClick={onClick} path={path} data-testid="article-tag" />;
};

ArticleTag.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ArticleTag.defaultProps = {
  onClick: () => {},
};

export default ArticleTag;
